import axios from "axios";
import { getInterceptor } from "../interceptor/interceptor";
import {
  IAddToCartParams,
  IAddressResponse,
  IAuthResponse,
  IBannerData,
  IBrands,
  ICartActionParams,
  ICartList,
  ICategoryMenu,
  ICities,
  IColors,
  IData,
  IDataWithPagination,
  ILoginParams,
  IMessage,
  IOrder,
  IPostAddressParams,
  IProductDetail,
  IProductList,
  IProfile,
  IRegisterParams,
  ISettings,
  ISizes,
  IStoriesData,
} from "@/types/global.types";
import Cookie from "@/utils/cookies.utils";

export const shared = {
  getBrands: async (params?: {
    category: string | null;
    textQuery?: string;
  }) => {
    const res = await getInterceptor().get<IData<IBrands[]>>("/brand", {
      params,
    });

    return res.data;
  },
  getColors: async (params?: {
    category: string | null;
    textQuery?: string;
  }) => {
    const res = await getInterceptor().get<IData<IColors[]>>("/color", {
      params,
    });

    return res.data;
  },
  getSizes: async (params?: {
    category: string | null;
    textQuery?: string;
  }) => {
    const res = await getInterceptor().get<IData<ISizes[]>>("/size", {
      params,
    });

    return res.data;
  },
  getMenu: async () => {
    const res = await getInterceptor().get<IData<ICategoryMenu[]>>(
      "/category/menu"
    );

    return res.data;
  },
  getStoriesSlider: async (params: { category: string }) => {
    const res = await getInterceptor().get<IData<IStoriesData[]>>("/story", {
      params,
    });

    return res.data;
  },
  getCities: async () => {
    const res = await getInterceptor().get<IData<ICities[]>>("/city");
    return res.data;
  },
  getAddressList: async () => {
    const res = await getInterceptor(true).get<IData<IAddressResponse[]>>(
      "/address"
    );
    return res.data;
  },
  getProfile: async () => {
    const res = await getInterceptor(true).get<IData<IProfile>>("/profile");
    return res.data;
  },
  postAddNewAddress: async (params: IPostAddressParams) => {
    const res = await getInterceptor(true).post<IData<IAddressResponse>>(
      "/address/add",
      params
    );
    return res.data;
  },
  putUpdateAddress: async (params: IPostAddressParams, id: number) => {
    const res = await getInterceptor(true).put<IData<IAddressResponse>>(
      `/address/${id}`,
      params
    );
    return res.data;
  },
  deleteAddress: async (id: number) => {
    const res = await getInterceptor(true).delete<{
      data: null;
      message: string;
      errors: unknown;
    }>(`/address/${id}`);
    return res.data;
  },
  getLogout: async () => {
    const res = await getInterceptor(true).get("/logout");

    return res.data;
  },
  getSettings: async () => {
    const res = await getInterceptor().get<
      IData<ISettings> & {
        message: string;
        errors: string;
      }
    >("/settings");

    return res.data;
  },
};

export const pages = {
  getProductBanner: async (params: { category: string }) => {
    const res = await getInterceptor().get<IData<IBannerData[]>>("/banner", {
      params,
    });

    return res.data;
  },
  getProductList: async (slug: string, page: number, queryParams?: string) => {
    const customSlug = slug === "search" ? "" : slug;
    const mainUrl = slug === "search" ? "products" : "category/";

    const res = await getInterceptor().get<IDataWithPagination<IProductList[]>>(
      `/${mainUrl}${customSlug}?page=${page}${queryParams}`
    );

    return res.data;
  },
  getProductDetail: async (slug: string) => {
    const res = await getInterceptor().get<IData<IProductDetail>>(
      `/products/${slug}`
    );

    return res.data;
  },
  getCartList: async () => {
    const uuid = Cookie.get("access_token") ? undefined : Cookie.get("uuid");

    const res = await getInterceptor(true).get<IData<ICartList>>(`/cart`, {
      params: {
        uuid,
      },
    });

    return res.data;
  },
  getOrderList: async () => {
    const res = await getInterceptor(true).get<IData<IOrder[]>>(`/order`);

    return res.data;
  },
  postRegister: async (params: IRegisterParams) => {
    const res = await getInterceptor().post<IData<IAuthResponse> & IMessage>(
      `/register`,
      params
    );

    return res.data;
  },
  postLogin: async (params: ILoginParams) => {
    const res = await getInterceptor().post<IData<IAuthResponse> & IMessage>(
      `/login`,
      params
    );

    return res.data;
  },
  postAddToCart: async (params: IAddToCartParams) => {
    const res = await getInterceptor(true).post<IData<ICartList>>(
      `/cart/add`,
      params
    );

    return res.data;
  },
  postPlusCart: async (params: ICartActionParams) => {
    const res = await getInterceptor(true).put<IData<ICartList>>(
      `/cart/increase/${params.cart_id}`,
      {
        uuid: Cookie.get("uuid"),
      }
    );

    return res.data;
  },
  postMinusCart: async (params: ICartActionParams) => {
    const res = await getInterceptor(true).put<IData<ICartList>>(
      `/cart/decrease/${params.cart_id}`,
      {
        uuid: Cookie.get("uuid"),
      }
    );

    return res.data;
  },
  deleteCart: async (params: ICartActionParams) => {
    const res = await getInterceptor(true).delete<IData<ICartList>>(
      `/cart/remove/${params.cart_id}`,
      {
        params: {
          uuid: Cookie.get("uuid"),
        },
      }
    );

    return res.data;
  },
  postPayment: async (params: { address: number }) => {
    const res = await getInterceptor(true).post<any>(`/order`, null, {
      params,
    });

    return res.data;
  },
  putSettings: async <T>(params: T) => {
    const res = await getInterceptor(true).put<any>(`/profile`, null, {
      params,
    });

    return res.data;
  },
  postContact: async <T>(params: T) => {
    const res = await getInterceptor(true).post(`/contact`, params);

    return res.data;
  },
};

export const ssrReq = {
  getProfile: async (token: string) => {
    const res = await getInterceptor(true).get<IData<IProfile>>("/profile", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return res.data;
  },
  getCartList: async (token: string) => {
    const res = await getInterceptor(true).get<IData<ICartList>>(`/cart`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return res.data;
  },
};
